<template>
  <div class="container failed-order-container">
    <div class="row failed-order-row">
      <div class="col-12 head-order-failed">
        <cart-container>
          <template slot="cartitem">
            <div>
              <h2 class="text-center"> {{ $t("Order.ORDER_FAILED") }} </h2>
              <p class="text-center mt-2 order-failed-message"> {{ $t("Order.ORDER_FAILED_MESSAGE") }} </p>
            </div>
            <!-- <router-link class="btn btn-theme mt-4 button-element shop-more-btn-text" to="products/sandwich">
              {{ $t("Order.SHOP_MORE_BUTTON_TEXT") }}
            </router-link> -->
            <a class="btn btn-theme mt-4 button-element" v-if="sandwichSuccessData.deep_link && !sandwichSuccessData.payment_type" href="#" @click="openLink">{{ $t('Order.SHOP_MORE_BUTTON_TEXT') }}</a>
            <a href="#" class="btn btn-theme mt-4 button-element" v-else @click="goToHome">{{ $t('Order.SHOP_MORE_BUTTON_TEXT') }}</a>
          </template>
        </cart-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const CartContainer = () => import("@/components/cart/CartContainer");

export default {
  name: "OrderFailed",
  components: {
    CartContainer,
  },
  computed:{
    ...mapGetters({ sandwichSuccessData:'getSandwichSuccessData' })
  },
  methods: {
      goToHome () {
      if ( window.Navigation ) {
        window.Navigation.postMessage('navigate-to-home');
      }
    },
    openLink(){
      if(this.sandwichSuccessData.deep_link){
        console.log(this.sandwichSuccessData.deep_link.indexOf("deeplink://"));
        if(this.sandwichSuccessData.deep_link.indexOf("deeplink://") < 0) {
          window.open('//'+this.sandwichSuccessData.deep_link); 
        }else{
          window.open(this.sandwichSuccessData.deep_link); 
        }
      }
    }
  }
};
</script>